<template>
  <div id="activityAdd">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/activityCases' }">活动案例</el-breadcrumb-item>
			<el-breadcrumb-item>添加活动</el-breadcrumb-item>
		</el-breadcrumb>
    <el-form
      :model="activityForm"
      ref="activityForm"
      :rules="rules"
      label-width="140px"
      class="active-form"
      style="padding-top: 20px;box-sizing: border-box"
      >
      <el-row style="width: 90%;margin-left:5%">
        <el-col :span="12">
          <el-form-item label-width="80px" label="活动名称">
            <el-input v-model="activityForm.name" type="text" placeholder="请输入活动名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" label="活动来源">
            <el-input v-model="activityForm.source" type="text" placeholder="请输入活动来源"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label-width="80px" label="活动链接" prop="url">
            <el-input v-model="activityForm.url" type="text" placeholder="请输入活动链接"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item class="investSubmit">
        <el-button type="primary" @click="submitForm('activityForm')" size="medium" class="searchBtn">发布</el-button>
        <el-button type="primary" @click="$router.push('/activityCases')" size="medium" class="searchBtn">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { success } from "@/utils/notification";

export default {
  name: 'activityAdd',
  data() {
    const urlTest = (rule, value, callback) => {
      let urlRegExp = /^(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$/
      if(!urlRegExp.test(value)){
        callback("链接格式错误，请填写正确的网址")
        this.activityForm.url = ''
      }
    };
    return {
      id: Number(sessionStorage.organizationId),
      activityForm: {
        organ_id: Number(sessionStorage.organizationId),
        type: "activity",
        name: "",
        source: "",
        url: ""
      },
      rules: {
        url: [
          { validator: urlTest, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm(){
      if(this.$route.query.id == undefined) { //添加
        this.$http.post(this.URL.adminUrl.mediaOrActivityList, this.activityForm).then(res => {
          this.$router.push('/activityCases')
          success ("添加成功")
        });
      }else{ //编辑
        this.$http.put(this.URL.adminUrl.mediaOrActivityList +'/'+ this.$route.query.id, this.activityForm).then(res => {
          this.$router.push('/activityCases')
          success ("编辑成功")
        });
      }
    }
  },
  created() {
    if(this.$route.query.id != undefined) {
      this.$http.get(this.URL.adminUrl.mediaOrActivityList +'/'+ this.$route.query.id).then(res => {
        this.activityForm = res.data.data
      });
    }
  }
}
</script>
<style lang="less" scoped>
  #activityAdd{
    .active-form{
      margin-top: 30px;
    }
  }
</style>
